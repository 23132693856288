import React, { Component } from 'react';
import { Header,Table,Grid,Dimmer,Loader,Icon,Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {paymentActions} from '../_actions';
import {history,toaster_dispatch,ADMIN} from '../_helpers';
import SidebarProfile from './SidebarProfile';
import {FormattedMessage,FormattedNumber,FormattedDate,injectIntl} from 'react-intl';
import {styles} from './Styles.jsx';




class Invoices extends Component {
    constructor(props) {
        super(props);
        let userIn = JSON.parse(localStorage.getItem('userIn'));
        this.state = { 
            prevPayment : props.payment,
            invoices: [],
            active_role:  userIn.user.role,
            org_member: userIn.user.active_org?true:false, 
         }
        props.dispatch(paymentActions.getCustomerInvoices());
    }


    static getDerivedStateFromProps(props, state) {
        if((props.payment!==state.prevPayment) && (props.payment.invoices)) {
            const invoices = props.payment.invoices;
            return {
                prevPayment : props.payment,
                invoices: invoices}
                }

        return null;
    }


    seeInvoice (event,invoice) {
        event.preventDefault();
        history.push('/invoicedetails/',{invoice:invoice});
    }



    addInvoicesRows(invoices){
        const {intl } = this.props;
        return invoices.map((invoice,index) => {
                return (
                    <Table.Row key={index} >
                        <Table.Cell width={4}>
                                {invoice &&
                                  invoice.finalized_at && 
                                  <FormattedDate value={new Date(invoice.finalized_at)}
                                                 year='numeric'
                                                 month='long'
                                                  day='2-digit'/>}
                        </Table.Cell>
                        <Table.Cell width={5} textAlign='left'>
                                <FormattedMessage id="invoices.table.row.invoice.period.title"
                                                  defaultMessage='Invoice of '/>{' '}
                                 <FormattedDate value={new Date(invoice.finalized_at)}
                                                year='numeric'
                                                month='long'/>                 
                        </Table.Cell>
                        <Table.Cell width={2} textAlign='center'>
                            <FormattedNumber value={invoice  && invoice.total/100} style="currency" maximumFractionDigits="2"  currencyDisplay="symbol" currency={invoice.currency }/>
                        </Table.Cell>
                        <Table.Cell width={4} textAlign='right'>
                            <a href={invoice.invoice_pdf} 
                               //onClick={(event) => this.seeInvoice(event,invoice)}
                               target="_blank"><FormattedMessage id="invoices.table.row.invoice.link.title"
                                                                 defaultMessage='Invoice details'/>
                            </a>
                        </Table.Cell>
                     </Table.Row>
                );
        });
    }

    render() { 
        const { alert,dispatch,intl,loading } = this.props;
        const { invoices,org_member,active_role } = this.state;
        let paidInvoices =  invoices.filter( invoice => { return invoice.status === 'paid'; });
        return ( 
                <SidebarProfile >
                        <Grid stackable style={styles.FontLato12}>
                             <Grid.Row columns={1} >
                                <Grid.Column width={8}>
                                    <Header as='h1'>
                                        <Icon name='edit' color='blue' size='huge'/>
                                        <Header.Content>
                                            <FormattedMessage id="invoices.top.title.header"
                                                              defaultMessage='Invoices'/>
                                            <Header.Subheader><FormattedMessage id="invoices.top.subtitle"
                                                              defaultMessage='Per period'/></Header.Subheader>
                                        </Header.Content>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            {alert.message && toaster_dispatch(dispatch,alert,intl)}
                            {org_member && active_role<ADMIN &&
                             <Grid.Row columns={1}/>
                            }
                            {org_member && active_role<ADMIN &&
                             <Grid.Row columns={1}/>
                            }
                            {org_member && active_role<ADMIN &&
                                <Grid.Row columns={1}>
                                    <Grid.Column width={8}>
                                        <Message compact info icon size='large'>
                                            <Icon name='settings' color='teal'/>
                                            <Message.Content>
                                                <Message.Header>
                                                    <FormattedMessage id="invoices.role.message.title"
                                                                        defaultMessage='Invoices'/>
                                                </Message.Header>
                                                <Icon name='lock' color='teal'/>
                                                <FormattedMessage id="invoices.role.message.details"
                                                                defaultMessage='You should have Administrator privilege.' />
                                                <br/> <br/>
                                            </Message.Content>
                                        </Message>
                                    </Grid.Column>
                                </Grid.Row>}
                            <Grid.Row columns={1} >
                                <Grid.Column /*width={12}*/  mobile={16} tablet={14} computer={12}>
                                    <Dimmer.Dimmable dimmed={org_member &&  active_role<ADMIN} blurring>
                                        <Dimmer active={org_member &&  active_role<ADMIN} blurring inverted>
                                                    {/*  <Loader inverted content='No Privilege' /> */}
                                        </Dimmer>
                                        <br/>
                                        <Header as='h3' color='grey' textAlign='left' style={styles.CardTitleGrey}>
                                            <FormattedMessage id="invoices.card.title"
                                                               defaultMessage='Invoices history overview'/>
                                        </Header>
                                        <br/><br/>
                                        {loading &&
                                        <Dimmer active inverted>
                                            <Loader inverted content='Loading' />
                                        </Dimmer> } 
                                        {paidInvoices && paidInvoices.length > 0 &&
                                        <Table selectable 
                                           stackable 
                                           compact 
                                           striped
                                           //singleline 
                                           style={styles.LabelGrey}>
                                            <Table.Header style={styles.LabelBlackBold}>
                                                <Table.Row>
                                                    <Table.HeaderCell width={4}>
                                                    <FormattedMessage id="invoices.table.column.date"
                                                                    defaultMessage='Date'/>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={5} textAlign='left'>
                                                        <FormattedMessage id="invoices.table.column.description"
                                                                        defaultMessage='Description'/>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={2} textAlign='center'>
                                                        <FormattedMessage id="invoices.table.column.amount"
                                                                        defaultMessage='Amount'/>
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell width={4} textAlign='right'></Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body style={styles.LabelGrey}>
                                            {paidInvoices  && paidInvoices.length > 0
                                                && this.addInvoicesRows(paidInvoices)}
                                            </Table.Body>
                                        </Table>}
                                        {paidInvoices && paidInvoices.length == 0 &&
                                            <p style={styles.LabelGrey}>
                                               <FormattedMessage id="invoices.card.message.no.invoice"
                                                                 defaultMessage='No available invoice'/>
                                            </p>
                                        }
                                         </Dimmer.Dimmable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                </SidebarProfile>
        )
    }
}

function mapStateToProps(state) {
    const { payment,alert,dispatch } = state;
    const { loading } = state.payment;
    return {
        loading,
        payment,
        alert,
        dispatch,
    };
}

const connectedInvoices = injectIntl(connect(mapStateToProps)(Invoices));
export { connectedInvoices as Invoices };
 

const ButtonNoColor = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    //'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
  }

const ButtonGreen = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
  }

  const ButtonGreenRecipe = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '30px',
    'line-height': '30px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '13px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
  }

  const ButtonGreenMargin = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
  }

  const ButtonGreenNoMargin = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }

  const ButtonGreenNoMarginOrder = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '36px',
    'line-height': '36px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }

  const ButtonGreenNoMarginMenu = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '32px',
    'line-height': '32px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '12px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }

  const ButtonGreenAddNoMarginOrder = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }

  const ButtonBlueAddNoMarginOrder = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }

  const ButtonGreyAddNoMarginOrder = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 500,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#e69251',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
  }


  const ButtonBlue = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '15px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#498caa',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}

const ButtonBlueAsIcon = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '60px',
    'line-height': '60px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '16px',
    'font-weight': 700,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd'/*#68a8ed*/,
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}

const ButtonBlueAsIconUpper = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '60px',
    'line-height': '60px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '6px',
    'font-size': '16px',
    'font-weight': 700,
    'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd'/*#68a8ed*/,
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}

const ButtonBlueAsIconSmall = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '50px',
    'line-height': '50px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 700,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd'/*#68a8ed*/,
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}


const ButtonHomeCarousel = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '3.5vw',
    //'line-height': '2px',
    'padding': '1.2vw',
    //'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '1.1vw',
    //'font-weight': 600,
    'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd'/*#68a8ed*/,
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    //'margin-top': '1vw',
    //'margin-left': '0.5vw',
}

const ButtonHomeCarouselMobile = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '11vw',
    //'line-height': '2px',
    'padding': '3vw',
    //'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '4.0vw',
    //'font-weight': 600,
    'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#63a4dd'/*#68a8ed*/,
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '0.5em',
    //'margin-left': '0.5vw',
}

const ButtonPurple = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#ce4c5b',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}

const ButtonRed = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#ce4c5b',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}
 
const ButtonGrey = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '14px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#bfbfbf',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '0.5em'
}


const ButtonUpperPurple = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '18px',
    'font-weight': 600,
    'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#6772e5',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
}

const ButtonHomePurple = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '16px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#6772e5',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '0px',
    'margin-left': '0.5em'
}

const ButtonHomeBlack = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '16px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#000000',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    //'margin-top': '10px'
    'margin-left': '0.5em'
}

const ButtonHomeWhite = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#000',
    'border-radius': '4px',
    'font-size': '16px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#efefef',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '0px'

}

const ButtonGreenInvoice = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-size': '15px',
    'font-weight': 600,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#66b2b2',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '10px',
    'margin-left': '30px'
  }

  const ButtonBluePremium = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '50px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-family': 'Lato', 
    'font-size': '16px',
    'font-weight': 300,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#7facf4',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '5px',
    'margin-left': '0.6em'
}

/* const ButtonBluePremiumHeader = {
    'white-space': 'nowrap',
    'border': 0,
    'outline': 0,
    'display': 'inline-block',
    'height': '40px',
    'line-height': '40px',
    'padding': '0 14px',
    'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
    'color': '#fff',
    'border-radius': '4px',
    'font-family': 'Lato', 
    'font-size': '13px',
    'font-weight': 300,
    //'text-transform': 'uppercase',
    'letter-spacing': '0.025em',
    'background-color': '#7facf4',
    'text-decoration': 'none',
    '-webkit-transition': 'all 150ms ease',
    'transition': 'all 150ms ease',
    'margin-top': '5px',
    'margin-left': '0.6em'
} */

const HeaderTitleGreen = {
    //color: '#66b2b2',
    fontWeight: '500',
    fontSize: '16px',
    letterSpacing: '0.025em',
}

const LabelGrey = {
    color: '#6b7c93',
    fontWeight: '300',
    fontSize: '14px',
    letterSpacing: '0.025em',
}

const LabelGreySmall = {
    color: '#6b7c93',
    fontWeight: '300',
    fontSize: '12px',
    letterSpacing: '0.025em',
    paddingLeft:'1em'
}

const LabelGreyBig = {
    color: '#6b7c93',
    fontWeight: '300',
    fontSize: '17px',
    letterSpacing: '0.025em',
}

const LabelRed = {
    color: '#a83232',
    fontWeight: '600',
    fontSize: '14px',
    letterSpacing: '0.025em',
}

const CardTitlePurpleGrey = {
    color: '#6772e5',
    fontWeight: '600',
    fontSize: '22px',
    letterSpacing: '0.025em',
}


const PageTitleBlack = {
    color: '#000000',
    fontWeight: '600',
    fontSize: '22px',
    letterSpacing: '0.025em',
}

const CardTitleGrey = {
    color: '#919191',
    fontWeight: '600',
    fontSize: '22px',
    letterSpacing: '0.025em',
}


const LabelBlackBold = {
    color: '#000000',
    fontWeight: '800',
    fontSize: '15px',
    letterSpacing: '0.025em',
    //fontWeight: 'bold',
}


const LabelBlackBoldBig = {
    color: '#000000',
    fontWeight: '800',
    fontSize: '20px',
    letterSpacing: '0.025em',
    //fontWeight: 'bold',
}


const Form = {
    'margin-bottom':'40px',
    'padding-bottom': '40px',
    //'border-bottom': '3px solid #e6ebf1',
    'paddingLeft':'1em'
}

const FormCheckout = {
    'margin-bottom':'40px',
    'padding-bottom': '40px',
    //'border-bottom': '3px solid #e6ebf1',
    'paddingLeft':'1em'
}


const Modal = {
    'margin-top': 'auto !important',
    display: 'inline-block !important',
    position: 'relative',
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'grey',
}


const FontVerdana14 = {
    'font-family': 'verdana', 
    'font-size': '14px',
    'color': 'grey',
};

const FontVerdana12 = {
    'font-family': 'verdana', 
    'font-size': '12px',
    'color': 'grey',
    border:'none',
};

const FontLato11 = {
    'font-family': 'Lato', 
    'font-size': '11px',
    'color': 'grey',
    //border:'none',
};

const FontLato10 = {
    'font-family': 'Lato', 
    'font-size': '10px',
    'color': 'grey',
    //border:'none',
};

const FontLato12 = {
    'font-family': 'Lato', 
    'font-size': '12px',
    'color': 'grey',
    //border:'none',
};

const FontLato12Bold = {
    'font-family': 'Lato', 
    'font-size': '12px',
    'color': 'black',
    fontWeight: 'bold',
    //border:'none',
};

const FontLato13 = {
    'font-family': 'Lato', 
    'font-size': '13px',
    'color': 'grey',
    border:'none',
};

const FontLato13Border = {
    'font-family': 'Lato', 
    'font-size': '13px',
    'color': 'grey',
};

const FontLato13Bold = {
    'font-family': 'Lato', 
    'font-size': '13px',
    fontWeight: 'bold',
    'color': 'grey',
    //border:'none',
};

const FontLato13BoldBlack = {
    'font-family': 'Lato', 
    'font-size': '13px',
    fontWeight: 'bold',
    'color': 'black',
    //border:'none',
};

const FontLato14Black = {
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'black',
    border:'none',
};

const FontLato14Border = {
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'grey',
};

const FontLato14BoldGrey = {
    'font-family': 'Lato', 
    'font-size': '14px',
    fontWeight: 'bold',
    'color': 'grey',
    border:'none',
};

const FontLato14BoldBlack = {
    'font-family': 'Lato', 
    'font-size': '14px',
    fontWeight: 'bold',
    'color': 'black',
    //border:'none',
};


const FontLato14Grey = {
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'grey',
    border:'none',
};

const FontLato14Bold = {
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'grey',
    fontWeight: 'bold',
    //border:'none',
};

const FontLato14BoldPadded = {
    'font-family': 'Lato', 
    'font-size': '14px',
    'color': 'grey',
    fontWeight: 'bold',
    marginLeft: '2em'
};

const FontLato16 = {
    'font-family': 'Lato', 
    'font-size': '16px',
    'color': 'grey',
    border:'none',
};

const FontLato16Bold = {
    'font-family': 'Lato', 
    'font-size': '16px',
    'color': 'grey',
    fontWeight: 'bold',
    border:'none',
};

const FontLato18= {
    'font-family': 'Lato', 
    'font-size': '18px',
    'color': 'grey',
    border:'none',
};

const FontLato18Bold = {
    'font-family': 'Lato', 
    'font-size': '18px',
    'color': 'grey',
    fontWeight: 'bold',
    border:'none',
};

const image = { 
    'position': 'relative',
    'width': '100%', /* for IE 6 */
};

const textMainTopLeftImage = { 
    position: 'absolute',
    top: '150px',
    left: '50px',
    width: '100%', 
    'color': 'black',
};

const textDetailTopLeftImage = { 
    position: 'absolute',
    top: '200px',
    left: '100px',
    width: '100%', 
    'color': 'black',
};


const FontLato14BoldTeal = {
    'font-family': 'Lato', 
    'font-size': '16px',
    fontWeight: 'bold',
    'color': '#66b2b2',
    //border:'none',
};

const FontLato14Teal = {
    'font-family': 'Lato', 
    'font-size': '14px',
    //fontWeight: 'bold',
    'color': '#66b2b2',
    //border:'none',
};


export const styles = {
    ButtonGreen: ButtonGreen,
    ButtonGreenMargin:ButtonGreenMargin,
    ButtonGreenNoMargin:ButtonGreenNoMargin,
    ButtonGreenNoMarginOrder:ButtonGreenNoMarginOrder,
    ButtonGreenNoMarginMenu:ButtonGreenNoMarginMenu,
    ButtonGreenAddNoMarginOrder:ButtonGreenAddNoMarginOrder,
    ButtonGreenRecipe:ButtonGreenRecipe, 
    ButtonRed: ButtonRed,
    ButtonBlue: ButtonBlue,
    ButtonBlueAsIcon:ButtonBlueAsIcon,
    ButtonBlueAsIconUpper:ButtonBlueAsIconUpper,
    ButtonBlueAsIconSmall:ButtonBlueAsIconSmall,
    ButtonBlueAddNoMarginOrder:ButtonBlueAddNoMarginOrder,
    ButtonGreyAddNoMarginOrder:ButtonGreyAddNoMarginOrder,
    ButtonGrey:ButtonGrey,
    ButtonBluePremium:ButtonBluePremium,
    ButtonUpperPurple:ButtonUpperPurple,
    ButtonGreenInvoice:ButtonGreenInvoice,
    //ButtonBluePremiumHeader:ButtonBluePremiumHeader,
    LabelGrey: LabelGrey,
    LabelGreySmall:LabelGreySmall,
    LabelRed: LabelRed,
    LabelGreyBig: LabelGreyBig,
    CardTitlePurpleGrey:CardTitlePurpleGrey,
    LabelBlackBold:LabelBlackBold,
    LabelBlackBoldBig:LabelBlackBoldBig,
    PageTitleBlack:PageTitleBlack,
    CardTitleGrey:CardTitleGrey,
    ButtonHomePurple:ButtonHomePurple,
    ButtonHomeBlack:ButtonHomeBlack,
    ButtonHomeWhite:ButtonHomeWhite,
    ButtonHomeCarousel:ButtonHomeCarousel,
    ButtonHomeCarouselMobile:ButtonHomeCarouselMobile,
    HeaderTitleGreen:HeaderTitleGreen,
    Form:Form,
    FormCheckout:FormCheckout,
    Modal:Modal,
    FontVerdana14:FontVerdana14,
    FontVerdana12:FontVerdana12,
    FontLato10:FontLato10,
    FontLato11:FontLato11,
    FontLato12:FontLato12,
    FontLato12Bold:FontLato12Bold,
    FontLato13:FontLato13,
    FontLato13Border:FontLato13Border,
    FontLato13BoldBlack:FontLato13BoldBlack,
    FontLato13Bold:FontLato13Bold,
    FontLato14Grey:FontLato14Grey,
    FontLato14Black:FontLato14Black,
    FontLato14Border:FontLato14Border,
    FontLato14Bold:FontLato14Bold,
    FontLato14BoldBlack:FontLato14BoldBlack,
    FontLato14BoldPadded:FontLato14BoldPadded,
    FontLato14BoldGrey:FontLato14BoldGrey,
    FontLato14BoldTeal:FontLato14BoldTeal,
    FontLato14Teal:FontLato14Teal,
    FontLato16:FontLato16,
    FontLato16Bold:FontLato16Bold,
    FontLato18:FontLato18,
    FontLato18Bold:FontLato18Bold,
    image:image,
    textMainTopLeftImage:textMainTopLeftImage,
    textDetailTopLeftImage:textDetailTopLeftImage,
}
// CheckoutForm.js
import React from 'react';
import {injectStripe,CardElement} from 'react-stripe-elements';
import {Button,Form,Segment,Icon,Dropdown, Divider,Header} from 'semantic-ui-react';
import { toaster_dispatch,trim } from '../../_helpers';
import {paymentActions,userActions} from '../../_actions'
import { connect } from 'react-redux';
import {injectIntl,FormattedMessage,FormattedNumber} from 'react-intl';
import {styles} from '../Styles.jsx';
import TagManager from 'react-gtm-module'

/* const inlineStyle = {
    button : {
            'white-space': 'nowrap',
            'border': 0,
            'outline': 0,
            'display': 'inline-block',
            'height': '40px',
            'line-height': '40px',
            'padding': '0 14px',
            'box-shadow': '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
            'color': '#fff',
            'border-radius': '4px',
            'font-size': '15px',
            'font-weight': 600,
            'text-transform': 'uppercase',
            'letter-spacing': '0.025em',
            'background-color': '#6772e5',
            'text-decoration': 'none',
            '-webkit-transition': 'all 150ms ease',
            'transition': 'all 150ms ease',
            'margin-top': '10px'
    },
    label : {
        'color': '#6b7c93',
        'font-weight': '300',
        'font-size': '17px',
        'letter-spacing': '0.025em',
    },
    Form :{
        'margin-bottom':'40px',
        'padding-bottom': '40px',
        //'border-bottom': '3px solid #e6ebf1',
    },
  }; */


const createOptions = (fontSize, padding) => {
    return {
      style: {
        base: {
          fontSize,
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
          ...(padding ? {padding} : {}),
        },
        invalid: {
          color: '#9e2146',
        },
      },
    };
  };


  const myrange = [1,2,3,4,5,6,7,8,9,10];

//CheckoutForm renders the input field and a button and injects
//this.props.stripe.createToken  via props
//The token with the encrypted credit card info is sent to my backend
// So I can send it to stripe

class CheckoutForm extends React.Component {

  constructor(props) {
    super(props);
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state =  {
      userIn: userIn,
      resp_message: "",
      card_errors: "",
      subscribing : false,
      //firstName : "",
      //lastName : "",
      name: "",
      address: "",
      city: "",
      postal: "",
      country: "",
      //phone: "",
      state: "",
      quantity_product: 1,
    }; 
  }

/*   onFirstNameChange = (e) => {
    e.preventDefault();
    this.setState({ firstName: e.target.value });
  }

  onLastNameChange = (e) => {
    e.preventDefault();
    this.setState({ lastName: e.target.value });
  } */

  onNameChange = (e) => {
    e.preventDefault();
    this.setState({ name: e.target.value });
  }

  onAdressChange = (e) => {
    e.preventDefault();
    this.setState({ address: e.target.value });
  }

  onCityChange = (e) => {
    e.preventDefault();
    this.setState({ city: e.target.value });
  }

  onPostalChange = (e) => {
    e.preventDefault();
    this.setState({ postal: e.target.value });
  }

  onCountryChange = (e) => {
    e.preventDefault();
    this.setState({ country: e.target.value });
  }

  onStateChange = (e) => {
    e.preventDefault();
    this.setState({ state: e.target.value });
  }

  onQuantityProductChange = (e,data) => {
    e.preventDefault();
    this.setState({ quantity_product: data.value });
  }


  handleCardErrors = card_dets => {
    console.log("Card Section dets", card_dets);
    if (card_dets.error) {
      this.setState({ card_errors: card_dets.error.message });
    } else {
      this.setState({ card_errors: "" });
    }
  }

/*   handleNameValidation(name){
      if(name !== ""){
          if(!name.match(/^[a-zA-Z]+$/)){
            return false;
          }      	
      }
      return true;
  }

  handleZipCodeValidation(zipcode){
      if(zipcode !== ""){
          if (!isNumeric(zipcode)){
              return false;
          }
      }
      return true
  }

  handleValidation(){
      const { last_name,first_name,zipcode } = this.state;

      if (!this.handleNameValidation(last_name)){ 
          return false;
      }
      if (!this.handleNameValidation(first_name)){ 
          return false;
      }
      if (!isNumeric(zipcode)){
          return false;
      }
      return true;
  } */


  cancelSubscription = (event) => {
    event.preventDefault();
    const { dispatch} = this.props;
    dispatch(paymentActions.unsubscribe());
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const {userIn,name,/*firstName,lastName,*/address,city,country,postal,state,quantity_product} = this.state;
    const { dispatch,type,mode,bill_locale} = this.props;
    this.setState({ card_errors: "", resp_message: "" });
    /*let fullName = "";
     if (userIn && userIn.user){
      fullName = userIn.user.username;
    }
    if (firstName && lastName){
      fullName = firstName + ' ' + lastName;
    } */
    //console.log(fullName);
    // Within the context of `Elements`, this call to createToken knows which Element to
    // tokenize, since there's only one in this group.
    return this.props.stripe
      .createToken({ type: "card",
                     name: name,
                     address_line1 : address,
                     address_city : city,
                     address_zip : postal,
                     address_country : country,
                     address_state : state})
      .then( result => {
        if (result.error) {
          console.log("THERE IS AN ERROR IN YOUR FORM", result.error);
          return this.setState({ card_errors: result.error.message });
        } else {
          /*console.log(
            "Received Stripe token ---> SENDING TO SERVER: ",
            result.token
          );*/

          //this.setState({ subscribing: true });
          this.props.callback(true);

          let pay_infos =  { membership:type,
                             locale:bill_locale,
                             mode:mode,
                             "stripe_token":result.token.id,
                             quantity: quantity_product}
          dispatch(paymentActions.subscribe(pay_infos));

         //Set user billing address
/*           if (userIn && userIn.user && 
            (lastName!="" || firstName!="" || address!="" || city!="" || postal!="" || country!="" || state!=""))
          {
            const user_infos = {
                     username:userIn.user.username,
                     last_name: trim(lastName),
                     first_name: trim(firstName),
                     address: address,
                     city: city,
                     zipcode: postal,
                     country: country,
                     state: state }
             dispatch(userActions.update(user_infos,'',true)); 
          } */

          if (userIn && userIn.user)
          {
            let tagManagerArgs = {
              dataLayer: {
                  membership_type: type,
                  username: userIn.user.username,
                  email: userIn.user.email,
                  page: 'CheckoutForm'
              },
              dataLayerName: 'PageDataLayer'
            }
  
            TagManager.dataLayer(tagManagerArgs)
          }


        }
    });
  };

  render() {
    const { dispatch ,intl,coupons,bill_locale} = this.props;
    const {quantity_product} = this.state;
    return (
      <Segment>
        {/*{subscribing &&
          <Dimmer active inverted>
              <Loader inverted content='Loading' />
          </Dimmer>} */}
        <Divider horizontal>
            <Header as='h2'>
                <Icon name='payment' />
                <Header.Content><FormattedMessage id="checkoutform.form.title.plan"
                                  defaultMessage="PLAN"/></Header.Content>
            </Header>
        </Divider>
        <Form style={styles.FormCheckout} onSubmit={this.handleSubmit}>
            <Form.Field>
              <br/><br/><br/> 
              <label style={styles.CardTitlePurpleGrey}> 
                {this.props.name} {' '}
                { this.props.mode =='Monthly' &&
                  <FormattedMessage id="checkoutform.form.plan.mode.month.name"
                                    defaultMessage="monthly"/>
                }
                { this.props.mode =='Annually' &&
                  <FormattedMessage id="checkoutform.form.plan.mode.year.name"
                                    defaultMessage="annually"/>
                }
                {' '} {/* : {' '} */}
{/*                   {coupons && coupons.length == 1 && 
                     this.props.mode =='Monthly' && this.props.type == 'Professional' &&
                  <FormattedMessage id="checkoutform.form.plan.coupon.message"
                                    defaultMessage="1€ first month then"/>
                }{' '}
              <FormattedNumber value={this.props.price}/>
                {intl.formatMessage({id:'currency.symbol'})}
                 { this.props.mode =='Annually' && 
                      <FormattedMessage id="checkoutform.form.plan.mode.year"
                                        defaultMessage="/year"/>}
                  { this.props.mode =='Monthly' && 
                      <FormattedMessage id="checkoutform.form.plan.mode.month"
                                        defaultMessage="/month"/>} */}
{/*                   { this.props.mode =='Monthly' && this.props.type == 'Professional' &&   
                    coupons && coupons.length == 1 && 
                    <FormattedMessage id="checkoutform.form.plan.coupon.message"
                                     defaultMessage="1€ first month then"/>
                  }{' '}  */}    
                  <br/><br/>                             
                  { this.props.mode =='Monthly' &&
                    <span>
                      {coupons && coupons.length == 1 && 
                        <span>
                          <FormattedNumber value={Number(this.props.price * quantity_product) - Number(coupons[0].discount_amount)} 
                                              style="currency"
                                              minimumFractionDigits={(Number(this.props.price*quantity_product) - Number(coupons[0].discount_amount)) % 1 === 0 ? 0 : 2}
                                              maximumFractionDigits={(Number(this.props.price*quantity_product) - Number(coupons[0].discount_amount)) % 1 === 0 ? 0 : 2}
                                              currencyDisplay="symbol" 
                                              currency={(bill_locale == "fr"?'EUR':'USD')}/>{' '}
                          <FormattedMessage id="checkoutform.form.plan.month.coupon.message"
                                      defaultMessage="first month then "/>
                        </span>
                      }{' '}
                      <FormattedNumber value={Number(this.props.price * quantity_product)} 
                                       style="currency"
                                       minimumFractionDigits={Number(this.props.price * quantity_product) % 1 === 0 ? 0 : 2}
                                       maximumFractionDigits={Number(this.props.price * quantity_product) % 1 === 0 ? 0 : 2}
                                       currencyDisplay="symbol" 
                                       currency={(bill_locale == "fr"?'EUR':'USD')}/>
                      <FormattedMessage id="checkoutform.form.plan.month.mode.month"
                                        defaultMessage="/month"/>
                      <br/>
                      <span style={{color: 'grey',fontWeight: '600',fontSize: '14px'}}>
                      <FormattedMessage id="checkoutform.form.plan.month.mode.month.vat"
                                        defaultMessage="total amount VAT incl. is"/>{' '}
                      <FormattedNumber value={Number(this.props.price * quantity_product * 1.2)} 
                                       style="currency"
                                       minimumFractionDigits={Number(this.props.price * quantity_product * 1.2) % 1 === 0 ? 0 : 2}
                                       maximumFractionDigits={Number(this.props.price * quantity_product * 1.2 ) % 1 === 0 ? 0 : 2}
                                       currencyDisplay="symbol" 
                                       currency={(bill_locale == "fr"?'EUR':'USD')}/>
                      </span>
                    </span>}
                  { this.props.mode =='Annually' &&
                    <span>
                       {coupons && coupons.length == 1 && 
                        <span>
                          <FormattedNumber value={Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount)} 
                                          style="currency"
                                          minimumFractionDigits={(Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount)) % 1 === 0 ? 0 : 2}
                                          maximumFractionDigits={(Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount)) % 1 === 0 ? 0 : 2}
                                          currencyDisplay="symbol" 
                                          currency={(bill_locale == "fr"?'EUR':'USD')}/>
                          <FormattedMessage id="checkoutform.form.plan.annual.mode.year"
                                            defaultMessage="/year"/>
                          {'  ('}
                          <FormattedNumber value={(Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount))/12} 
                                          style="currency"
                                          minimumFractionDigits={((Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount))/12) % 1 === 0 ? 0 : 2}
                                          maximumFractionDigits={((Number(this.props.price * quantity_product)-Number(coupons[0].discount_amount))/12) % 1 === 0 ? 0 : 2}
                                          currencyDisplay="symbol" 
                                          currency={(bill_locale == "fr"?'EUR':'USD')}/>
                          <FormattedMessage id="checkoutform.form.plan.mode.month"
                                            defaultMessage="/month"/>{')'}
                           {'  '}    
                           <FormattedMessage id="checkoutform.form.plan.annual.coupon.premessage"
                                      defaultMessage="with"/>{' '}              
                          <FormattedNumber value={Number(coupons[0].discount_amount)} 
                                              style="currency"
                                              minimumFractionDigits={Number(coupons[0].discount_amount) % 1 === 0 ? 0 : 2}
                                              maximumFractionDigits={Number(coupons[0].discount_amount) % 1 === 0 ? 0 : 2}
                                              currencyDisplay="symbol" 
                                              currency={(bill_locale == "fr"?'EUR':'USD')}/>{' '}
                          <FormattedMessage id="checkoutform.form.plan.annual.coupon.message"
                                      defaultMessage="discount included"/>
                      </span>}
                      {(!coupons || coupons.length == 0) &&
                        <span>
                          <FormattedNumber value={Number(this.props.price * quantity_product)} 
                                          style="currency"
                                          minimumFractionDigits={Number(this.props.price * quantity_product) % 1 === 0 ? 0 : 2}
                                          maximumFractionDigits={Number(this.props.price * quantity_product) % 1 === 0 ? 0 : 2}
                                          currencyDisplay="symbol" 
                                          currency={(bill_locale == "fr"?'EUR':'USD')}/>
                          <FormattedMessage id="checkoutform.form.plan.annual.mode.year"
                                            defaultMessage="/year"/>
                          {' '}
                          <span style={{fontSize:'14px'}}>&nbsp;{'   ('}
                            <FormattedMessage id="checkoutform.form.plan.mode.month.explain"
                                            defaultMessage="equivalent"/>{' '}
                            <FormattedNumber value={Number(this.props.price * quantity_product)/12} 
                                          style="currency"
                                          minimumFractionDigits={(Number(this.props.price * quantity_product)/12) % 1 === 0 ? 0 : 2}
                                          maximumFractionDigits={(Number(this.props.price * quantity_product)/12) % 1 === 0 ? 0 : 2}
                                          currencyDisplay="symbol" 
                                          currency={(bill_locale == "fr"?'EUR':'USD')}/>
                            <FormattedMessage id="checkoutform.form.plan.mode.month"
                                            defaultMessage="/month"/>{')'}
                          </span>
                          <br/>
                          <span style={{color: 'grey',fontWeight: '600',fontSize: '14px'}}>
                          <FormattedMessage id="checkoutform.form.plan.month.mode.year.vat"
                                            defaultMessage="total amount VAT incl. is"/>{' '}
                          <FormattedNumber value={Number(this.props.price * quantity_product * 1.2)} 
                                          style="currency"
                                          minimumFractionDigits={Number(this.props.price * quantity_product * 1.2) % 1 === 0 ? 0 : 2}
                                          maximumFractionDigits={Number(this.props.price * quantity_product * 1.2 ) % 1 === 0 ? 0 : 2}
                                          currencyDisplay="symbol" 
                                          currency={(bill_locale == "fr"?'EUR':'USD')}/>
                          </span>
                      </span>}
                   </span>
                  }
                </label>
                <br/><br/>  
                { this.props.type =='Entreprise' &&
                    <span style={styles.FontLato16}>
                      <FormattedMessage id="checkoutform.form.plan.Entreprise.organisation.before"
                                      defaultMessage="I need to manage"/>&nbsp;
                    <Dropdown selection compact
                              options= {myrange.map(merange => ({key: merange, text:merange, value: merange }))}
                              value = {quantity_product}
                              onChange={this.onQuantityProductChange} 
                              style={{color: '#6772e5',fontWeight: '800',fontSize: '16px'}}/>&nbsp;
                    <span>
                    <span style={{color: '#6772e5',fontWeight: '800',fontSize: '16px'}}>
                    {'  '}<FormattedMessage id="checkoutform.form.plan.Entreprise.organisation"
                                      defaultMessage="organization(s)"/>&nbsp;
                        </span><FormattedMessage id="checkoutform.form.plan.Entreprise.organisation2"
                                      defaultMessage="or company(s) on my account."/></span>
                         <br/><br/>
                  </span>}
                <br/>
            </Form.Field>
            <Form.Field>
                <span style={styles.FontLato16} /*style={styles.LabelGreyBig}*/>
                      <FormattedMessage id="checkoutform.form.card.details"
                                        defaultMessage="Enter card details :"/>
                </span>
                <Segment>
                   <CardElement 
                    {...createOptions('18px')}
                    onChange={this.handleCardErrors}/>
                  <div role="warn">
                      <h3>{this.state.card_errors}</h3>
                  </div>
                </Segment>  
            </Form.Field>
 {/*            <br/> 
            <Form.Field>
              <br/>  
              <label style={styles.LabelGreyBig}>
                      <FormattedMessage id="checkoutform.form.billing.title"
                                        defaultMessage="Billing Address"/>
              </label>
          </Form.Field> */}
        {bill_locale == 'en' &&
          <Segment>
          <Form.Group widths='equal'>
            <FormattedMessage id="checkoutform.form.billing.placeholder.name"
                                defaultMessage="Name of card holder ">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onNameChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
{/*                <FormattedMessage id="checkoutform.form.billing.placeholder.firstname"
                                defaultMessage="First name">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onFirstNameChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
                <FormattedMessage id="checkoutform.form.billing.placeholder.lastname"
                                defaultMessage="Last name">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onLastNameChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage>  */}
          </Form.Group>
          <Form.Group  widths='equal'>
              <FormattedMessage id="checkoutform.form.billing.placeholder.streetaddress"
                                defaultMessage="Street">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onAdressChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
          </Form.Group>
          <Form.Group widths='equal'>
              <FormattedMessage id="checkoutform.form.billing.placeholder.city"
                                defaultMessage="City">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onCityChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
{/*                 <FormattedMessage id="checkoutform.form.billing.placeholder.stateregion"
                                defaultMessage="State / Region">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onStateChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage>  */}
                <FormattedMessage id="checkoutform.form.billing.placeholder.postalcode"
                                defaultMessage="Postal code">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onPostalChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
                <FormattedMessage id="checkoutform.form.billing.placeholder.country"
                                defaultMessage="Country">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onCountryChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
          </Form.Group>
   {/*        <Form.Group widths='equal'>
              <FormattedMessage id="checkoutform.form.billing.placeholder.country"
                                defaultMessage="Country">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onCountryChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
                 <FormattedMessage id="checkoutform.form.billing.placeholder.phonenumber"
                                defaultMessage="Phone number">
                  {placeholder =>
                   <Form.Input fluid
                      type="text"
                      placeholder={placeholder}
                      onChange={this.onPhoneChange}
                      style={{padding:'0.25em'}}/>
                  }
                </FormattedMessage> 
          </Form.Group>*/}
          </Segment>}
          <p style={styles.FontLato12}>
            <Icon color='violet' name='lock'/>
            <FormattedMessage id="checkoutform.form.billing.buy.security.message"
                              defaultMessage="Your credit card is securely stored by our payment provider Stripe (certified highest level available).">  
            </FormattedMessage>
          </p>
          <br/>
          <div align='center'>
          <Button style={styles.ButtonUpperPurple}>
                      <FormattedMessage id="checkoutform.form.button.subscribe"
                                        defaultMessage="Subscribe"/>
          </Button></div>
          {this.state.resp_message && <h1>{this.state.resp_message}</h1>}
          {alert.message && toaster_dispatch(dispatch,alert,intl)}
          <br/>
          <p style={styles.FontLato14}>
            <Icon color='violet' name='info circle'/>
            <FormattedMessage id="checkoutform.form.billing.buy.update.message"
                              defaultMessage="If you already have a subscription, it will be updated and only prorated difference will be charged.">  
            </FormattedMessage>
          </p>
        </Form>
        {/* <Button style={styles.ButtonBlue} onClick={this.cancelSubscription}>Cancel subscription (end of period)</Button> */}
      </Segment>
    );
  }
}


function mapStateToProps(state) {
  const { payment,alert,dispatch } = state;
  const { coupons } = state.payment;
  return {
      payment,
      coupons,
      alert,
      dispatch,
  };
}

const connectedCheckoutForm = injectStripe(injectIntl(connect(mapStateToProps)(CheckoutForm)));
export { connectedCheckoutForm as CheckoutForm};
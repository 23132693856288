import { paymentConstants } from '../_constants';

export function payment(state = {}, action) {
  switch (action.type) {
    case paymentConstants.GET_REQUEST:
      return {
        loading: true
      };
    case paymentConstants.GET_SUCCESS:
      return {
        ...state,
        loading: false,
        subscription: action.subscription
      };
    case paymentConstants.GET_FAILURE:
      return { 
        error: action.error
      };
    case paymentConstants.PUT_REQUEST:
      return {
        subscribing: true
      };
    case paymentConstants.PUT_SUCCESS:
      return {
        subscription: action.subscription
      };
    case paymentConstants.PUT_FAILURE:
      return { 
        error: action.error
      };
    case paymentConstants.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        deleting: true  
/*         ...state,
        items: state.items.map(user =>
          user.id === action.id
            ? { ...user, deleting: true }
            : user
        ) */
      };
    case paymentConstants.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        deleting: true    
        //items: state.items.filter(user => user.id !== action.id)
      };
    case paymentConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
      return {
        error: action.error
/*         ...state,
        items: state.items.map(user => {
          if (user.id === action.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: action.error };
          }

          return user;
        }) */
      };
      case paymentConstants.GET_MEMBERSHIPS_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.GET_MEMBERSHIPS_SUCCESS:
        return {
          loading: false,
          memberships: action.memberships
        };
      case paymentConstants.GET_MEMBERSHIPS_FAILURE:
        return { 
          error: action.error
        };
      case paymentConstants.GET_MEMBERSHIP_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.GET_MEMBERSHIP_SUCCESS:
        return {
          ...state,
          loading: false,
          customer: action.customer
        };
      case paymentConstants.GET_MEMBERSHIP_FAILURE:
        return { 
          error: action.error
        };

      case paymentConstants.GET_INVOICES_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.GET_INVOICES_SUCCESS:
        return {
          loading: false,
          invoices: action.invoices
        };
      case paymentConstants.GET_INVOICES_FAILURE:
        return { 
          error: action.error
        };

      case paymentConstants.GET_CHARGES_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.GET_CHARGES_SUCCESS:
        return {
          charges: action.charges
        };
      case paymentConstants.GET_CHARGES_FAILURE:
        return { 
          error: action.error
        };

      case paymentConstants.RENDER_INVOICE_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.RENDER_INVOICE_SUCCESS:
        return {
          invoice: action.invoice
        };
      case paymentConstants.RENDER_INVOICE_FAILURE:
        return { 
          error: action.error
        };
      case paymentConstants.CUSTOMER_UPDATE_REQUEST:
        return {
          loading: true
        };
      case paymentConstants.CUSTOMER_UPDATE_SUCCESS:
        return {
          customer: action.customer
        };
      case paymentConstants.CUSTOMER_UPDATE_FAILURE:
        return { 
          error: action.error
        };
        case paymentConstants.SEND_VOUCHER_REQUEST:
          return {
            loading_voucher: true
          };
        case paymentConstants.SEND_VOUCHER_SUCCESS:
          return {
            sent_voucher:true,
            voucher: action.voucher
          };
        case paymentConstants.SEND_VOUCHER_FAILURE:
          return { 
            error_voucher:true,
            error: action.error
          };
        case paymentConstants.VALIDATE_VOUCHER_REQUEST:
            return {
              validating_voucher: true
            };
        case paymentConstants.VALIDATE_VOUCHER_SUCCESS:
            return {
              validated_voucher:true,
              voucher: action.voucher
            };
        case paymentConstants.VALIDATE_VOUCHER_FAILURE:
            return { 
              error_voucher:true,
              error: action.error
            };
         case paymentConstants.ACTIVATE_VOUCHER_REQUEST:
              return {
                validating_voucher: true
              };
        case paymentConstants.ACTIVATE_VOUCHER_SUCCESS:
              return {
                validated_voucher:true,
                voucher: action.voucher
              };
        case paymentConstants.ACTIVATE_VOUCHER_FAILURE:
              return { 
                error_voucher:true,
                error: action.error
              };
        case paymentConstants.GET_COUPONS_REQUEST:
            return {
                loading: true
            };
        case paymentConstants.GET_COUPONS_SUCCESS:
              return {
                ...state,
                loading: false,
                coupons: action.coupons
              };
         case paymentConstants.GET_COUPONS_FAILURE:
              return { 
                ...state,
                error: action.error
              };        
      default:
        return state
  }
}

import React, { Component } from 'react'
import { Sidebar, Segment, Responsive, List, Label, Container, Icon,Button } from 'semantic-ui-react'
//import { Link } from 'react-router-dom';
import {FormattedMessage,FormattedDate} from 'react-intl';
import { connect } from 'react-redux';
import { paymentActions } from '../_actions';
import {history,extractRoleFromCode,ADMIN} from '../_helpers';
import {styles} from './Styles';

import img_chef from '../Assets/chef2.png'

const containerStyle = {
  height: 'auto',
  width: '1400px',
  padding: '1em 0em',
  border:'none',
  'font-family': 'Lato', 
  'font-size': '12px',
  'color': 'grey',
  //'border-style':'solid',
  //'border-width': '0.5px',
 'background-color': '#f9f9f9',
 // backgroundImage: `url(${backgroundImg})`,
 // backgroundSize: 'cover'
};


const inlineImageStyle3 = {
  img:{
        '-webkit-filter': 'brightness(98%)',
        '-moz-filter': 'brightness(98%)',
  },  
};


class SideLeftUncoverDashboard extends Component {
  constructor(props) {
    super(props);
    let userIn = JSON.parse(localStorage.getItem('userIn'));
    this.state = { visible: false,
                   username : userIn.user.username,
                   access: extractRoleFromCode(userIn.user.role),
                   organisation: userIn.user.active_org,
                   active_role: userIn.user.role }
    props.dispatch(paymentActions.getSubscriptionState());
}  

  
  onSubmit = (e) => {
    e.preventDefault();
    history.push({pathname: '/profilepremium', state: {toggled: true}})
  }
  
  render() {
 //width: '100%'
    const {subscription } = this.props;
    const {username,access,organisation,active_role } = this.state;


    return (
      <div>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Sidebar.Pushable as={Container} style={containerStyle}>
{/*             <Sidebar as={Menu}  fixed='left' selection  animation='uncover' width='thin' visible={true} icon='labeled' vertical >
                <Menu.Item>Recipe actions</Menu.Item>
                <Menu.Item name='Add Recipe' as={Link} to='/newrecipe' >
                  <Icon name='add' size='mini'/>
                  Add Recipe
                </Menu.Item>
                <Menu.Item name='Download Recipe'>
                  <Icon name='download' size='mini'/>
                  Download Recipe
                </Menu.Item>  */}
             <Sidebar as={List} selection animation='uncover' width='thin' visible={true} icon='labeled' vertical='true' size='large'>
                <div style={{'text-align':'center', 'paddingTop' : '6em'}}>
                  <p style={{'font-size':'13px','letter-spacing': '0.025em'}}>
                      <FormattedDate
                          value={new Date()}
                          day="numeric"
                          month="short"
                          year="numeric"
                          hour="numeric"
                          minute="numeric"/> 
                  </p> 
                  <br/>
                  <img width={'45px'} height={'auto'} src={img_chef} style={inlineImageStyle3.img}
                             alt={'ratatool recipe calculation cost pricing'}/>
                       <p><FormattedMessage id="dashboard.chef.message"
                                          defaultMessage='Welcome'/>{' '}{username}</p> 
                    <br/><br/>
                    {organisation && <div>
                      <Icon name='building outline' size='big' style={{color:'#7facf4'}}/>
                      <span><p style={{'font-weight':'600'}}>{organisation.name}</p><Label size='small' color='lightgrey'>{access}</Label></span>                    
                    </div>}
{/*                     {!organisation &&<p>{<FormattedMessage id="dashboard.organisation.none"
                                                             defaultMessage='None'/>}</p>} */}
                    <br/> <br/> <br/>
                    {organisation && active_role >= ADMIN &&
                    <div>
                      <Icon name='users' size='big' style={{color:'#7facf4'}}/>
                          <p>{organisation.nb_users} {' '}<FormattedMessage id="dashboard.organisation.users"
                                                                            defaultMessage='user(s)'/></p>
                    </div>}
               </div>
              
{/*                 <List.Item disabled><FormattedMessage id="dashboard.computer.sidemenu.menu.top.title"
                                                       defaultMessage='Dashboard Actions'/>
                </List.Item>
                <List.Item name='New Ingredient' as={Link} to='/newingredient'>
                  <Icon name='add' color='blue'/>
                  <List.Content>
                    <List.Header><FormattedMessage id="dashboard.computer.sidemenu.menu.elem2.title"
                                                   defaultMessage='New Ingredient'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='New Recipe' as={Link} to='/newrecipe'>
                  <Icon name='add' color='blue' />
                  <List.Content>
                    <List.Header><FormattedMessage id="dashboard.computer.sidemenu.menu.elem1.title"
                                                   defaultMessage='New Recipe'/></List.Header>
                  </List.Content>
                </List.Item>
                <List.Item name='New Order' as={Link} to='/orders'>
                  <Icon name='add' color='blue'/>
                  <List.Content>
                    <List.Header><FormattedMessage id="dashboard.computer.sidemenu.menu.elem3.title"
                                                   defaultMessage='New Order'/></List.Header>
                  </List.Content>
                 </List.Item>
                 <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/> */}
                {/*  <List.Item name='Premium' as={Link} to='/pricing'> */}
                    {(subscription && ( !subscription.customer__membership__membership_type || subscription.customer__membership__membership_type=="Free") ) && 
                    <div style={{'text-align':'center', 'paddingTop' : '8em'}}>
                      <a style={{color:'grey'}}>
                            <FormattedMessage id="dashboard.computer.sidemenu.premium.title.message.subscribe"
                                              defaultMessage='Plan: Basic'/>
                      </a>
                      <Button style={styles.ButtonBluePremium}
                        onClick={(e) => {this.onSubmit(e)}}>
                        <FormattedMessage id="dashboard.button.premium"
                                          defaultMessage='Subscribe'/>
                      </Button>
                    </div>}
{/*                     <div style={{'text-align':'center', 'paddingTop' : '24em'}}>
                      <a style={{color:'grey'}} href='/contact'>
                        <FormattedMessage id="dashboard.help.message"
                                          defaultMessage='Need help ?'/>
                      </a>
                    </div> */}

                    
                 {/* </List.Item> */}
              </Sidebar> 
              <Sidebar.Pusher style={{ /*height: 'auto'*/overflowY: 'auto','min-height': '100vh', width: '100%', padding: '1em 2em',border:'none','background-color':'#fcfcfc' }}>
                  {this.props.children} 
              </Sidebar.Pusher>
            </Sidebar.Pushable>
        </Responsive>
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
{/*             <Menu secondary>
                <Menu.Item  onClick={() => this.setState({ visible: !this.state.visible })} >
                    <Icon name="sidebar"/><FormattedMessage id="dashboard.mobile.sidemenu.menu.top.title"
                                                            defaultMessage='Dashboard'/>
                </Menu.Item>          
            </Menu> */}
            <Sidebar.Pushable as={Segment} >
{/*                 <Sidebar 
                    width='thin'
                    as={Menu}    
                    animation="push" 
                    direction="top" 
                    visible={this.state.visible} 
                    icon="labeled" 
                    horizontal
                    selection
                    size='tiny'>
                  <Menu.Item name='New Ingred' as={Link} to='/newingredient' >
                      <Icon name='add' color='blue'/>
                        <FormattedMessage id="dashboard.mobile.sidemenu.menu.elem2.title"
                                          defaultMessage='New Ingredient'/>
                    </Menu.Item>
                    <Menu.Item name='New Recipe' as={Link} to='/newrecipe' >
                      <Icon name='add' color='blue'/>
                        <FormattedMessage id="dashboard.mobile.sidemenu.menu.elem1.title"
                                          defaultMessage='New Recipe'/>
                    </Menu.Item>
                    <Menu.Item name='New Order' as={Link} to='/orders' >
                      <Icon name='add' color='blue'/>
                        <FormattedMessage id="dashboard.mobile.sidemenu.menu.elem3.title"
                                          defaultMessage='New Order'/>
                    </Menu.Item>
                 </Sidebar> */}
                <Sidebar.Pusher dimmed={this.state.visible} style={{ minHeight: '100vh',overflow: 'auto' , padding: '1em 0em 2em 0em'}}>
                    {/*  <Segment style={{ minHeight: 500, padding: '1em 0em' }}> */}
                      {this.props.children}
                   {/* </Segment>  */}
                </Sidebar.Pusher>
            </Sidebar.Pushable>
         </Responsive>
      </div>
    )
  }
}

//export default SideLeftUncoverDashboard


function mapStateToProps(state) {
  const { alert,dispatch } = state;
  const { subscription } = state.payment;
  return {
       subscription,
      alert,
      dispatch,
  };
}

const connectedSideLeftUncoverDashboard = connect(mapStateToProps)(SideLeftUncoverDashboard);
export { connectedSideLeftUncoverDashboard as SideLeftUncoverDashboard };
export const paymentConstants = {
    GET_REQUEST: 'SUBSCRIPTION_GET_REQUEST',
    GET_SUCCESS: 'SUBSCRIPTION_GET_SUCCESS',
    GET_FAILURE: 'SUBSCRIPTION_GET_FAILURE',

    PUT_REQUEST: 'SUBSCRIPTION_PUT_REQUEST',
    PUT_SUCCESS: 'SUBSCRIPTION_PUT_SUCCESS',
    PUT_FAILURE: 'SUBSCRIPTION_PUT_FAILURE',

    DELETE_REQUEST: 'SUBSCRIPTION_DELETE_REQUEST',
    DELETE_SUCCESS: 'SUBSCRIPTION_DELETE_SUCCESS',
    DELETE_FAILURE: 'SUBSCRIPTION_DELET_FAILURE',

    GET_MEMBERSHIPS_REQUEST: 'MEMBERSHIPS_GET_REQUEST',
    GET_MEMBERSHIPS_SUCCESS: 'MEMBERSHIPS_GET_SUCCESS',
    GET_MEMBERSHIPS_FAILURE: 'MEMBERSHIPS_GET_FAILURE',

    GET_MEMBERSHIP_REQUEST: 'MEMBERSHIP_GET_REQUEST',
    GET_MEMBERSHIP_SUCCESS: 'MEMBERSHIP_GET_SUCCESS',
    GET_MEMBERSHIP_FAILURE: 'MEMBERSHIP_GET_FAILURE',

    GET_INVOICES_REQUEST: 'INVOICES_GET_REQUEST',
    GET_INVOICES_SUCCESS: 'INVOICES_GET_SUCCESS',
    GET_INVOICES_FAILURE: 'INVOICES_GET_FAILURE',

    GET_CHARGES_REQUEST: 'CHARGES_GET_REQUEST',
    GET_CHARGES_SUCCESS: 'CHARGES_GET_SUCCESS',
    GET_CHARGES_FAILURE: 'CHARGES_GET_FAILURE',

    RENDER_INVOICE_REQUEST: 'RENDER_INVOICE_REQUEST',
    RENDER_INVOICE_SUCCESS: 'RENDER_INVOICE_SUCCESS',
    RENDER_INVOICE_FAILURE: 'RENDER_INVOICE_FAILURE',

    CUSTOMER_UPDATE_REQUEST: 'CUSTOMER_UPDATE_REQUEST',
    CUSTOMER_UPDATE_SUCCESS: 'CUSTOMER_UPDATE_SUCCESS',
    CUSTOMER_UPDATE_FAILURE: 'CUSTOMER_UPDATE_FAILURE',

    SEND_VOUCHER_REQUEST: 'SEND_VOUCHER_REQUEST',
    SEND_VOUCHER_SUCCESS: 'SEND_VOUCHER_SUCCESS',
    SEND_VOUCHER_FAILURE: 'SEND_VOUCHER_FAILURE',

    VALIDATE_VOUCHER_REQUEST: 'VALIDATE_VOUCHER_REQUEST',
    VALIDATE_VOUCHER_SUCCESS: 'VALIDATE_VOUCHER_SUCCESS',
    VALIDATE_VOUCHER_FAILURE: 'VALIDATE_VOUCHER_FAILURE',

    ACTIVATE_VOUCHER_REQUEST: 'ACTIVATE_VOUCHER_REQUEST',
    ACTIVATE_VOUCHER_SUCCESS: 'ACTIVATE_VOUCHER_SUCCESS',
    ACTIVATE_VOUCHER_FAILURE: 'ACTIVATE_VOUCHER_FAILURE',

    GET_COUPONS_REQUEST: 'COUPONS_GET_REQUEST',
    GET_COUPONS_SUCCESS: 'COUPONS_GET_SUCCESS',
    GET_COUPONS_FAILURE: 'COUPONS_GET_FAILURE',

};


export const stripeConstants = {
    // TEST KEY
    //STRIPE_PUBLIC_KEY :'pk_test_G1PmRhlqJ2WIr9MSuNFByz95'

    // LIVE KEY
    STRIPE_PUBLIC_KEY :'pk_live_NIa49g5bWM1eVe8czzpTYKZa'
           
};